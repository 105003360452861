import { useEffect, useState, useRef } from 'react';

import { Link } from 'react-router-dom';

import { Button } from '../Button';

import './Menu.css';


const MenuButtons = () => {
  return (
    <>
      <Link to="/"><Button text="🏠 Accueil" /></Link>
      <Link to="/services"><Button text="🤝 Prestations" /></Link>
      <Link to="/about"><Button text="🤓 A propos" /></Link>
      <Link to="/approach"><Button text="💡Mon approche" /></Link>
      <Link to="/reviews"><Button text="📝 Témoignages" /></Link>
    </>
  );
}

const MenuInNavBar = () => {
  return (
    <div className="menu-navbar">
      <MenuButtons />
    </div>
  );
}

const MenuInHamburger = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      toggleMenu();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <nav className="menu-hamburger" ref={menuRef} onClick={toggleMenu}>
      <div className="hamburger">
        ☰
      </div>
      {isOpen && (<div className="menu">
        <MenuButtons />
      </div>
      )
      }
    </nav>
  );
}

export const Menu = () => {
  return (
    <div>
      <MenuInNavBar />
      <MenuInHamburger />
    </div>
  );
}