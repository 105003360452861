import React from 'react';
import '../App.css';

import { Intro } from '../components/Intro';
import { Goals } from '../components/Goals';
import { DeservedAreas } from '../components/DeservedAreas';

export const Welcome = () => {

  return (
    <div className="App-welcome">
      <Intro />
      <Goals />
      <DeservedAreas />
    </div>
  );
}