import React from 'react';
import '../App.css';

import mail from '../images/mail.svg';
import facebook from '../images/facebook.svg';
import instagram from '../images/instagram.svg';


export const Contacts = () => {
  return (
    <div className="App-contacts">
      <div>06.52.70.21.33</div>
      <a href="mailto:kiffepaws.educ@gmail.com" className='put-spaces'><img src={mail} width="32" alt="mail" /></a>
      <a href="https://www.facebook.com/profile.php?id=61556683478682" className='put-spaces'><img src={facebook} width="32" alt="facebook" /></a>
      <a href="https://www.instagram.com/dr_natto/" className='put-spaces'><img src={instagram} width="32" alt="instagram" /></a>
    </div>
  );
}