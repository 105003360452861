import './Review.css'; // Import CSS file for styling reviews

interface ReviewProps {
  lines: string[],
  author: string,
  index: number
}

export const Review: React.FC<ReviewProps> = ({ lines, author, index }) => {
  const isEven = index % 2 === 0; // Determine if the review should be left-aligned or right-aligned

  return (
    <div className={`review ${isEven ? 'left' : 'right'}`}>
      <p className="review-text"> {lines.map((line, i) => (
        <p key={i} className="review-line">{line}</p>
      ))}
      </p>
      <p className="review-author">- {author}</p>
    </div>
  );
};