import '../App.css';

import { Button } from '../components/Button';
import { Review } from '../components/Review/Review';
import reviews from '../data/reviews.json';

export const Reviews = () => {

  return (
    <div>
      <div>
        <h1 className="small-h1">Voici quelques des témoignages de nos clients :</h1>
        {reviews.map((review, index) => (
          <Review key={index} lines={review.lines} author={review.author} index={index} />
        ))}
      </div>
      <div style={{ display: "inline-flex" }}>
        <Button text="Voir les avis Google" href="https://g.co/kgs/1wUjVVU" />
        <Button text="Voir les avis Facebook" href="https://www.facebook.com/profile.php?id=61556683478682" />
      </div>
    </div>
  );
}