import { Link } from 'react-router-dom';

import '../App.css';


export const Footer = () => {
  return (
    <div className="App-footer">
      © {new Date().getFullYear()} - KiffePaws Educ - &nbsp;
      <Link to="/legalnotice">Mentions légales</Link>
    </div>
  );
}