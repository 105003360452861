import '../App.css';

interface ButtonProps {
  text: string;
  href?: string;
}

export const Button: React.FC<ButtonProps> = ({ text, href }) => {
  const innerStyle = { textDecoration: 'none', padding: '10px', borderRadius: '20px', cursor: 'pointer', background: 'linear-gradient(180deg, rgba(255,211,161,1) 0%, rgba(234,161,81,1) 100%)', color: 'black', fontSize: 'calc(10px + 2vmin)' };
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
      {href ? <a href={href} style={innerStyle}>{text}</a> : <span style={innerStyle}>{text}</span>}
    </div>
  );
}