import '../App.css';

interface CardProps {
  name: string;
  image?: string;
  description?: string;
}

export const Card: React.FC<CardProps> = ({ name, image, description }) => {
  return (
    <div className="App-card">
      <figure>
        <img src={image} alt={name} width="250" />
        <figcaption>{name}</figcaption>
      </figure>
      {description && <p>{description}</p>}
    </div>
  );
}
