import '../App.css';

export const Services = () => {

  return (
    <div className="put-spaces">
      <h1 className="small-h1">Voici l'accompagnement d'éducation et de rééducation canine chez nous</h1>
      <h2>Déroulement</h2>
      <h3>Bilan comportemental (étude de comportement)</h3>
      <p>Avant de programmer les séances, une <strong>étude de comportement </strong> (50€) à domicile s'avère nécessaire afin de mieux identifier vos besoins ainsi que de mieux connaître votre loulou dans ses habitudes.
        En effet, il faut commencer par "connaître le terrain" pour mieux réussir !
        Grâce à la synthèse obtenue à la fin de ce bilan comportemental, je serai emmenée à estimer le nombre de séances nécessaires à vos attentes.
      </p>
      <h3>Séance "bienvenue"</h3>
      <p>L’objectif de cette séance est de pouvoir vous expliquer toutes les règles de vie à mettre en place à
        la maison, mais aussi traiter théoriquement tous les problèmes de votre chien.</p>
      <p>Les conseils en théorie, c'est génial ! Mais comme je considère que vous coacher
        en pratique est un élément essentiel, cette séance est offerte quelque soit le contrat souscrit avec moi.
      </p>
      <h3>La pratique ! Séances individuelles, séances collectives </h3>
      <p>
        Votre chien bénéficiera de séances individuelles comme collectives adaptées à ce que nous devons travailler. Chaque situation étant différente, c'est mon devoir <strong>d'adapter les séances</strong> afin de permettre une progression optimale.
      </p>
      <p>De même que chaque situation est différente, chaque chien l'est aussi, c'est pourquoi nous allons travailler avec ce qui le motive le plus.</p>
      <p>Suite à ce qui a été observé et étudié au cours du bilan comportemental, je serai apte à vous proposer un devis
        personnalisé.
      </p>
    </div>
  );
}