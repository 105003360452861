import '../App.css';

import check from '../images/check.svg';
import block from '../images/block.svg';

import natto from '../images/natto_small.png';
import juliaNatto from '../images/Julia-et-Natto-1_small.png';
import juliaNatto2 from '../images/Julia-et-Natto-2_small.png';
import kylingDiana from '../images/kylingDiana_small.png';
import funGoogle from '../images/funGoogle_small.png';
import instagram from '../images/instagram.svg';
import { ContactMe } from '../components/ContactMe';

export const About = () => {

  const painsNattoHome: string[][] = [
    [`Il était malpropre.`, `En dépit des nombreuses promenades, il ne faisait qu'à la maison les premiers jours, et ce n'était pas des petits besoins.`],
    [`Faisait de la protection de ressources.`, `Au bout du 3e jour, il a commencé à vouloir pincer tout animal qui passe à côté de sa gamelle.`],
    [`Aboyait pour n'importe quel stimuli.`, `Après une semaine de muet, le chien si stressé de la vie a commencé à aboyer pour tout et rien. Autant dire que les nuits étaient pourries.`,
      `Terrorisait la femme de ménage. Les premières fois il était timide et gentil, mais les fois suivantes il s'était mis à régir virulemment 
    sur elle à chaque fois qu'elle rentrait. Heureusement cela s'est réglé aprs quelque mois.`],
    [`Très craintif en intérieur.`, `Il suffisait de faire tomber une cuillère dans la cuisine pour qu'il se carapate et fasse une grève de la faim.`],
    [`Aboyait et grognait la femme de ménage à chacune de ses arrivées.`, `On devait surveiller qu'il ne la cogne pas !`],
    [`Etait fugueur.`, `En prenant confiance, il n'hésitait pas à fuguer dès que l'on ouvrait le portail. Une voisine nous l'a même ramené une fois. `]
  ];

  const painsNattoOutside: string[][] = [
    [`Etait craintif de tout.`, `Il faisait des bons faciles quand les gens se retournaient, quand des voitures approchaient de la chaussée, et c'est quand même une masse à tenir.`],
    [`Il n'écoutait pas grand chose et avait 0 focus.`, `On n'existait simplement pas. Le suivi naturel n'existait même pas avec lui.`],
    [`A un instinct de prédation développé.`, `Dans les champs, soit je le tenais et tombais, soit il me glissait entre les mains et revenait après 15 min.`],
    [`Il avait la phobie de la voiture.`, `Il avait tellement peur qu'il claquait des dents et tremblait à chaque trajet, couinait voire sautait partout sur les sièges.`],
    [`Il agressait certains congénères.`, `Il n'hésitait pas à "faire le kéké", pincer et a déjà plaqué gratuitement une femelle au sol.`]
  ];

  const oufNatto: string[] = [
    "Finis les pipis et cacas dans la cuisine.",
    "Mes nuits sont à nouveau tranquilles.",
    "Les chats (et l'oiseau) de la maison ont la paix.",
    "Les chutes de clés et de couverts par terre ne le font plus fuir.",
    "Adieu les fugues.",
    "Il maîtrise tous les ordres essentiels au quotidien et d'autres (traverse, monte, fait le mort, tourne, cherche)",
    "Comme il a du rappel, une bonne marche au pied et un super suivi naturel dans tout type d'environnement, je peux le lâcher.",
    "Je peux le promener dans tout type d'environnement, même les rues animées de Paris sans avoir de la panique à bord.",
    "J'ai même voyagé avec, et il s'est très bien comporté. Même les séjours en appartement, les ascenseurs, les bruits dans le couloir l'ont laissé indifférent.",
    "Il kiffe aller dans la voiture (un peu trop même).",
    "Il est très bien codé avec ses congénères et est même une aide pour les enseigner.",
    "Aujourd'hui, son comportement exemplaire fait tourner les têtes."
  ];

  return (
    <div className="App-about put-spaces">
      <h1 className="small-h1">Comment je suis devenue éducateur comportementaliste canin</h1>
      <div>Je m'appelle Julia, j'ai 28 ans et de base j'étais une informaticienne avant que tout bascule.</div>
      <img className='centered-image' src={juliaNatto} alt="Julia & Natto" width="300" />
      <div>
        <h2>Sommaire</h2>
        <ul>
          <li><a href="#section-1">J'ai eu des chiens toute ma vie</a></li>
          <li><a href="#section-2">Je me suis offert un cadeau empoisonné ?</a></li>
          <li><a href="#section-3">A la maison, il nous causait beaucoup d'ennuis</a></li>
          <li><a href="#section-4">En promenade, beaucoup de stress</a></li>
          <li><a href="#section-5">Le temps des progrès</a></li>
          <li><a href="#section-6">Aujourd'hui, je profite du quotidien avec mon chien</a></li>
        </ul>
      </div>

      <div id="section-1">
        <h2>J'ai eu des chiens toute ma vie 🐺🐺🐺🐺</h2>
        <p><strong>Nous avons toujours vécu entourés de chiens récupérés chiots. Ce qui est rigolo était que nous les avons eu 2
          à 2 avec des tempéramments totalement opposés</strong> : soit le chien était indépendant, peu obéissant,
          introverti et calme (le primitif), soit il était pot de colle, docile, extraverti et un peu fifou.
        </p>
        <p>
          La première paire de chiens était composée de Kyling, un Shar pei, et Diana, une briard. C'est la génération des années 90-2000's, je n'avais même pas de portable à l'époque. 😄
        </p>
        <img className='centered-image' src={kylingDiana} alt="Kyling et Diana" width="300" />
        <p>Puis la 2e paire composée de Fun, un bouvier bernois, et Google, une chow chow : la génération 2010 !</p>
        <img className='centered-image' src={funGoogle} alt="Fun et Google" width="300" />
        <p>Google était justement ma chienne à moi... qui malheureusement est décédée comme tous les autres.</p>
        <p><strong>Et comme j'ai eu des chiens toute ma vie et que j'avais à la fois envie de faire une bonne action et ne
          pas repasser par les phases chiot et adolescence, j'ai décidé pour la première fois d'adopter un chien de refuge d'un âge assez avancé. </strong></p>
      </div>

      <div id="section-2">
        <h2>Je me suis offert un cadeau empoisonné ? 💀</h2>
        <p><em>La transformation d'un chien à problèmes récupéré à plus de 5 ans en un super chien (et oui c'est possible !).</em></p>
        <p>
          Le premier facteur qui m'a entraîné à m'intéresser à la psychologie canine est mon chien actuel, Natto, un croisé akita chow border et autres.
          Avant lui, j'avais vécu avec 4 chiens que nous avons tous eus depuis chiots. À la mort douloureuse de ma chow chow Google,
          j'ai décidé d'adopter mon premier chien de refuge.
        </p>
        <img className='centered-image' src={natto} alt="natto" width="300" />
        <p>
          Natto est un chien très calme et très gentil. Lors de sa récupération, j'étais bien excitée et contente de pouvoir à nouveau bientôt partager mes longues balades avec un nouveau compagnon.
        </p>
        <p>Hélas, pensant récupérer un chien clé en main, j'ai également récupéré un chien avec une histoire pas très sympa et les séquelles qui vont avec.</p>
      </div>

      <div id="section-3">
        <h3>A la maison, il nous causait beaucoup d'ennuis </h3>
        {painsNattoHome.map((pain: string[]) => (
          <p className="list-item">
            <img src={block} className="check-image" width="16" alt="block" />
            <strong>{pain[0]}</strong> {pain[1]}
          </p>
        ))}
      </div>

      <div id="section-4">
        <h3>En promenade, beaucoup de stress</h3>
        {painsNattoOutside.map((pain: string[]) => (
          <p className="list-item">
            <img src={block} className="check-image" width="16" alt="block" />
            &nbsp;<strong>{pain[0]}</strong> {pain[1]}
          </p>
        ))}
      </div>

      <div id="section-5">
        <h2>Le temps des progrès</h2>
        <p>
          Après 4 mois chez nous, le chien a fait d'énormes progrès, mais il restait quand même craintif. C'est comme s'il fallait faire une croix sur
          les projets d'aventure avec lui et le laisser dans le calme dans l'environnement rural uniquement.
        </p>
        <p>
          Ouïe ouïe ouïe même après plus de 10 ans d'expérience avec des chiens différents et leur éducation, avoir été capable d'avoir eu
          un chow chow (très) obéissant; j'ai décidé de mettre mon égo de côté et apprendre des autres
          pour mieux comprendre mon chien actuel, qui a une histoire. <strong>C'est alors que je me forme pour régler
            cette crainte et ses derniers problèmes mais aussi pour commencer une nouvelle profession.</strong>
        </p>
      </div>

      <div id="section-6">
        <h3>Aujourd'hui, je profite du quotidien avec mon chien</h3>
        {oufNatto.map((ouf: string) => (
          <p className="list-item">
            <img src={check} className="check-image" width="16" alt="check" />
            &nbsp;{ouf}
          </p>
        ))}

        <p><strong>Franchement je n'y croyais pas vu le nombre de problèmes que le chien avait déjà, en plus de son âge.</strong></p>
        <p>
          Comme l'histoire de Natto a pu bien se finir, je vous aiderai également à écrire votre histoire et celle de votre loulou. Chaque histoire est différente, et c'est cela qui rend le métier intéressant.
          <strong> Qu'attendez-vous pour démarrer une nouvelle vie ?</strong>
        </p>

        <ContactMe />

        <p>
          Aujourd'hui, Natto est non seulement un colocataire agréable, mais aussi un collègue qui me servira dans bon nombre de mes séances. Ce sera l'occasion pour vous de faire sa connaissance.
        </p>
        <p>Pour suivre le quotidien de Natto ainsi qu'obtenir quelques tips: </p>
        <p>
          <a href="https://www.instagram.com/dr_natto/">
            <img className='centered-image' src={instagram} width="32" alt="instagram" />
          </a>
        </p>
        <img className='centered-image' src={juliaNatto2} alt="Julia & Natto 2" width="300" />
      </div>
    </div>
  );
}
