import React, { useState } from 'react';
import './IllustrationBlock.css';

interface IllustrationBlockProps {
  title: string;
  text: string;
  examples: string[];
}

export const IllustrationBlock: React.FC<IllustrationBlockProps> = ({ title, text, examples }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="collapsible-block">
      <h2>{title}</h2>
      <p>{text}</p>
      <div className="collapsible-section">
        <div className="collapse-header" onClick={toggleCollapse}>
          Exemples {isCollapsed ? '➤' : '▼'}
        </div>
        {!isCollapsed && (
          <ul className="examples-list">
            {examples.map((example, index) => (
              <li key={index}>{example}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
