import React from 'react';
import './GlassBlock.css';

interface GlassBlockProps {
  content: string;
}

export const GlassBlock: React.FC<GlassBlockProps> = ({ content }) => {
  return (
    <div className="glass-block">
      {content}
    </div>
  );
};