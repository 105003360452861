import '../App.css';

import { Card } from './Card';
import { ContactMe } from './ContactMe';

import tire from '../images/pains/tire_small.png';
import saute from '../images/pains/saute_small.png';
import detruit from '../images/pains/detruit_small.png';
import malpropre from '../images/pains/malpropre_small.png';
import vole from '../images/pains/vole_small.png';
import aboie from '../images/pains/aboie_small.png';
import fugue from '../images/pains/fugue_small.png';
import agressif from '../images/pains/agressif_small.png';
import craintif from '../images/pains/craintif_small.png';
import anxiete from '../images/pains/anxiete_small.png';
import rebelle from '../images/pains/rebelle_small.png';

import content from '../images/wishes/content_small.png';
import calme from '../images/wishes/calme_small.png';
import sage from '../images/wishes/sage_small.png';
import enLaisse from '../images/wishes/enLaisse_small.png';
import lacher from '../images/wishes/lacher_small.png';
import partout from '../images/wishes/partout_small.png';
import exemplaire from '../images/wishes/exemplaire_small.png';
import complicite from '../images/wishes/complicite_small.png';

export const Goals = () => {

  const wishes: string[][] = [
    ["Avoir un chien bien dans ses pattes 😄", content],
    ["Avoir un chien qui sait être calme 🧘", calme],
    ["Que votre chien soit sage à la maison aussi bien en votre présence qu'en votre absence 😇", sage],
    ["Promener sereinement votre chien en laisse 🤝", enLaisse],
    ["Pouvoir lâcher votre chien en toute circonstance 🦅", lacher],
    ["Emmener votre chien partout 🚗", partout],
    ["Que votre entourage admire l'exemplarité de votre chien 🤓", exemplaire],
    ["Avoir une superbe complicité ensemble, tout simplement ❤️", complicite],
  ];

  const pains: string[][] = [
    ["tire en laisse 🎿", tire],
    ["saute 🦘", saute],
    ["détruit 💣", detruit],
    ["est malpropre 🚽", malpropre],
    ["vole 🦝", vole],
    ["aboie intempestivement 🎷", aboie],
    ["fugue 🥷", fugue],
    ["est agressif 😡", agressif],
    ["est craintif 🫣", craintif],
    ["a de l'anxiété de séparation 😭", anxiete],
    ["est simplement 'rebelle' 🙅", rebelle]
  ];

  return (
    <div className="App-goals put-spaces">
      <p>Malgré le fait que vous l'aimiez, vous le subissez que ce soit à la maison, à l'extérieur voire les 2. Lui ainsi que votre relation avec votre entourage, votre santé et votre argent souffrez de la situation...</p>
      <div className="App-pains">
        <h2>Votre chien...</h2>
        {pains.map((pain: string[], index: number) => <Card key={index} name={pain[0]} image={pain[1]} />)}
        <ContactMe />
      </div>
      <div className="App-wishes">
        <h2>Vous souhaitez...</h2>
        {wishes.map((wish: string[], index: number) => <Card key={index} name={wish[0]} image={wish[1]} />)}
        <ContactMe />
      </div>
      <div>
      </div>
      <div>
        <p>Je suis là pour accompagner votre binôme afin de vous permettre de retrouver sérénité, fun et complicité.</p>
        <p>Pour plus d'informations, n'hésitez pas :</p>
        <ContactMe />
      </div>
    </div>
  );
}