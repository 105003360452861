import '../App.css';

import { Button } from './Button';

export const ContactMe = () => {
  const getAppropriateContact: () => string = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      return 'tel:0652702133';
    }
    return 'mailto:kiffepaws.educ@gmail.com';
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
      <Button text="Contactez-moi" href={getAppropriateContact()} />
    </div>
  );
}
